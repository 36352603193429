// import './App.css';
import React, { Fragment, useState } from 'react';

import styles from './App.module.css'
import Billie from '../../Images/Billie.png';
import Boss from '../../Images/Boss.png';
import Mentor from '../../Images/Mentor.png';
import Tanzmeister from '../../Images/Tanzmeister.png';
import Thorsten from '../../Images/Thorsten.png';

import Car01 from '../../Images/City01.JPG';
import Work from '../../Images/Work.jpg';
import Getup from '../../Images/Getup.JPG';
import FindWorkplace01 from '../../Images/FindWorkplace03.JPG';
import FindWorkplace02 from '../../Images/FindWorkplace02.JPG';
import DreamPlatforms from '../../Images/DreamPlatforms01.JPG';


import { Parallax, ParallaxLayer } from '@react-spring/parallax'

// var isMobile = false;

const offsets = {
    landing: 0.0,
    pfyh: 2.45,
    contact: 3.45,
    impressum: 4.45,
}

function App() 
{

    const [isMobile, setIsMobile] = useState(getIsMobile());

    function getIsMobile ()
    {
        return window.innerWidth < 900;
    }

    function renderDrawings ()
    {
        if (isMobile)
        {
            return (
                <Fragment>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Billie} style={{ width: "auto", height: "250px", position: "absolute", left: "15%", top: "70%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Boss} style={{ width: "auto", height: "170px", position: "absolute", left: "70%", top: "75%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.24}>
                        <img src={Mentor} style={{ width: "auto", height: "220px", position: "absolute", left: "10%", top: "15%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.15}>
                        <img src={Tanzmeister} style={{ width: "auto", height: "180px", position: "absolute", left: "70%", top: "10%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.2}>
                        <img src={Thorsten} style={{ width: "auto", height: "150px", position: "absolute", left: "50%", top: "70%" }} alt="Billie" />
                    </ParallaxLayer>
                </Fragment>
            )
        }
        else
        {
            return (
                <Fragment>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Billie} style={{ width: "auto", height: "350px", position: "absolute", left: "30%", top: "50%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Boss} style={{ width: "auto", height: "270px", position: "absolute", left: "80%", top: "40%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.24}>
                        <img src={Mentor} style={{ width: "auto", height: "250px", position: "absolute", left: "10%", top: "25%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.15}>
                        <img src={Tanzmeister} style={{ width: "auto", height: "240px", position: "absolute", left: "30%", top: "10%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.2}>
                        <img src={Thorsten} style={{ width: "auto", height: "200px", position: "absolute", left: "60%", top: "60%" }} alt="Billie" />
                    </ParallaxLayer>
                </Fragment>
            )
        }
    }

    function renderButtons ()
    {
        if (isMobile)
        {
            return (
                <Fragment>
                    <ParallaxLayer
                        offset={0}
                        speed={0.4}>
                        <button
                            className={styles.buttonMobile} style={{ top: "132%" }}
                            onClick={() => { window.open("https://store.steampowered.com/app/2111990/Lose_Control/", '_blank'); }}>
                            Go to <span style={{ fontWeight: "bold" }}>Steam</span> page
                        </button>
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.4}>
                        <button
                            className={styles.buttonMobile} style={{ top: "145%" }}
                            onClick={() => { window.open("https://twitter.com/PlayFromHeart", '_blank'); }}>
                            Check out <span style={{ fontWeight: "bold" }}>Twitter</span>
                        </button>
                    </ParallaxLayer>
                </Fragment>
            )
        }
        else
        {
            return (
                <Fragment>
                    <ParallaxLayer
                        offset={0}
                        speed={0.4}>
                        <button
                            className={styles.button} style={{ top: "115%" }}
                            onClick={() => { window.open("https://store.steampowered.com/app/2111990/Lose_Control/", '_blank'); }}>
                            Go to <span style={{ fontWeight: "bold" }}>Steam</span> page
                        </button>
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.4}>
                        <button
                            className={styles.button} style={{ top: "125%" }}
                            onClick={() => { window.open("https://twitter.com/PlayFromHeart", '_blank'); }}>
                            Check out <span style={{ fontWeight: "bold" }}>Twitter</span>
                        </button>
                    </ParallaxLayer>
                </Fragment>
            )
        }
    }

    function renderScreenshots ()
    {
        if (isMobile)
        {
            return (
                <Fragment>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Car01} style={{ width: "340px", height: "auto", position: "absolute", left: "calc(50% - 170px)", top: "160%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={FindWorkplace01} style={{ width: "340px", height: "auto", position: "absolute", left: "calc(50% - 170px)", top: "195%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Work} style={{ width: "340px", height: "auto", position: "absolute", left: "calc(50% - 170px)", top: "230%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={FindWorkplace02} style={{ width: "340px", height: "auto", position: "absolute", left: "calc(50% - 170px)", top: "265%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Getup} style={{ width: "340px", height: "auto", position: "absolute", left: "calc(50% - 170px)", top: "300%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={DreamPlatforms} style={{ width: "340px", height: "auto", position: "absolute", left: "calc(50% - 170px)", top: "335%" }} alt="Billie" />
                    </ParallaxLayer>
                </Fragment>
            )
        }
        else
        {
            return (
                <Fragment>
                    <ParallaxLayer
                        offset={0}
                        speed={0.25}>
                        <img src={Car01} style={{ width: "auto", height: "340px", position: "absolute", left: "8%", top: "140%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.19}>
                        <img src={FindWorkplace01} style={{ width: "auto", height: "300px", position: "absolute", left: "55%", top: "150%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.2}>
                        <img src={Work} style={{ width: "auto", height: "280px", position: "absolute", left: "12%", top: "200%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.24}>
                        <img src={FindWorkplace02} style={{ width: "auto", height: "300px", position: "absolute", left: "60%", top: "240%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.3}>
                        <img src={Getup} style={{ width: "auto", height: "340px", position: "absolute", left: "45%", top: "200%" }} alt="Billie" />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.28}>
                        <img src={DreamPlatforms} style={{ width: "auto", height: "340px", position: "absolute", left: "14%", top: "255%" }} alt="Billie" />
                    </ParallaxLayer>
                </Fragment>
            )
        }
    }

    return (
        <div className={styles.app}>
            <Parallax pages={isMobile ? 5.85 + 0.6 : 5.85}>

                {renderDrawings()}
                
                <ParallaxLayer
                    offset={offsets.landing}
                    speed={0.2}>
                    <div className={styles.header}>
                        <span className={isMobile ? styles.headlineMobile : styles.headline}>
                            LOSE CTRL
                        </span>
                        <span>
                            A game about the adventures of a young wo-man, <br />who struggles to take back control<br /> in a rapidly changing world.
                        </span>
                    </div>
                </ParallaxLayer>

                {renderButtons()}
                {renderScreenshots()}

                <ParallaxLayer
                    offset={isMobile ? offsets.pfyh + 0.6 : offsets.pfyh}
                    speed={0.2}>
                    <div className={styles.pfyhheader}>
                        <span className={isMobile ? styles.headlineMobile : styles.headline}>
                            PLAY FROM YOUR HEART
                        </span>
                        <span>
                            (UG Haftungsbeschränkt)
                        </span>
                        <span>
                            Indie Game Studio based in Berlin.
                        </span>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={isMobile ? offsets.contact + 0.6 : offsets.contact}
                    speed={0.2}>
                    <div className={styles.header}>
                        <span className={isMobile ? styles.headlineMobile : styles.headline}>
                            CONTACT
                        </span>
                        <span>
                            Twitter: @PlayFromHeart
                        </span>
                        <span>
                            E-Mail: playfromyourheartug[at]gmail.com
                        </span>
                        {/* <blockquote class="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">😲 we didn&#39;t tell you what we are working on!<br/><br/>(Wt) Lose Control is an <a href="https://twitter.com/hashtag/indiegame?src=hash&amp;ref_src=twsrc%5Etfw">#indiegame</a> about the absurdities and struggles of modern life with a twist: the story is being told through minigames and the controls are getting crazy out of hand.<a href="https://twitter.com/hashtag/gamedev?src=hash&amp;ref_src=twsrc%5Etfw">#gamedev</a> <a href="https://twitter.com/hashtag/indiedev?src=hash&amp;ref_src=twsrc%5Etfw">#indiedev</a> <a href="https://twitter.com/hashtag/gaming?src=hash&amp;ref_src=twsrc%5Etfw">#gaming</a> <a href="https://t.co/GqhpxFSAde">pic.twitter.com/GqhpxFSAde</a></p>&mdash; Play From Your Heart (@PlayFromHeart) <a href="https://twitter.com/PlayFromHeart/status/1501870568408240137?ref_src=twsrc%5Etfw">March 10, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}

                    </div>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={isMobile ? offsets.impressum + 0.6 : offsets.impressum}
                    speed={0.2}>
                    <div className={isMobile ? styles.impressumMobile : styles.impressum}>
                        <span className={isMobile ? styles.headlineMobile : styles.headline}>
                            IMPRESSUM
                        </span>
                        <span>
                            Haftung für Inhalte
                        </span>
                        <span>
                            Alle Inhalte unseres Internetauftritts wurden mit größter Sorgfalt und nach bestem Gewissen erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.

                            Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntniserlangung einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von den o.g. Rechtsverletzungen werden wir diese Inhalte unverzüglich entfernen.
                        </span>
                        <br/>
                        <span>
                            Haftungsbeschränkung für externe Links
                        </span>
                        <span>
                            Unsere Webseite enthält Links auf externe Webseiten Dritter. Auf die Inhalte dieser direkt oder indirekt verlinkten Webseiten haben wir keinen Einfluss. Daher können wir für die „externen Links“ auch keine Gewähr auf Richtigkeit der Inhalte übernehmen. Für die Inhalte der externen Links sind die jeweilige Anbieter oder Betreiber (Urheber) der Seiten verantwortlich.

                            Die externen Links wurden zum Zeitpunkt der Linksetzung auf eventuelle Rechtsverstöße überprüft und waren im Zeitpunkt der Linksetzung frei von rechtswidrigen Inhalten. Eine ständige inhaltliche Überprüfung der externen Links ist ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei direkten oder indirekten Verlinkungen auf die Webseiten Dritter, die außerhalb unseres Verantwortungsbereichs liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall nur bestehen, wenn wir von den Inhalten Kenntnis erlangen und es uns technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.

                            Diese Haftungsausschlusserklärung gilt auch innerhalb des eigenen Internetauftrittes „Name Ihrer Domain“ gesetzten Links und Verweise von Fragestellern, Blogeinträgern, Gästen des Diskussionsforums. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargestellten Informationen entstehen, haftet allein der Diensteanbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.

                            Werden uns Rechtsverletzungen bekannt, werden die externen Links durch uns unverzüglich entfernt.
                        </span>
                        <br />
                        <span>
                            Urheberrecht
                        </span>
                        <span>
                            Die auf unserer Webseite veröffentlichen Inhalte und Werke unterliegen dem deutschen Urheberrecht (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf) . Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung des geistigen Eigentums in ideeller und materieller Sicht des Urhebers außerhalb der Grenzen des Urheberrechtes bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen Urhebers i.S.d. Urhebergesetzes (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf ). Downloads und Kopien dieser Seite sind nur für den privaten und nicht kommerziellen Gebrauch erlaubt. Sind die Inhalte auf unserer Webseite nicht von uns erstellt wurden, sind die Urheberrechte Dritter zu beachten. Die Inhalte Dritter werden als solche kenntlich gemacht. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte unverzüglich entfernen.

                            Dieses Impressum wurde freundlicherweise von jurarat.de zur Verfügung gestellt.
                        </span>
                        {/* <blockquote class="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">😲 we didn&#39;t tell you what we are working on!<br/><br/>(Wt) Lose Control is an <a href="https://twitter.com/hashtag/indiegame?src=hash&amp;ref_src=twsrc%5Etfw">#indiegame</a> about the absurdities and struggles of modern life with a twist: the story is being told through minigames and the controls are getting crazy out of hand.<a href="https://twitter.com/hashtag/gamedev?src=hash&amp;ref_src=twsrc%5Etfw">#gamedev</a> <a href="https://twitter.com/hashtag/indiedev?src=hash&amp;ref_src=twsrc%5Etfw">#indiedev</a> <a href="https://twitter.com/hashtag/gaming?src=hash&amp;ref_src=twsrc%5Etfw">#gaming</a> <a href="https://t.co/GqhpxFSAde">pic.twitter.com/GqhpxFSAde</a></p>&mdash; Play From Your Heart (@PlayFromHeart) <a href="https://twitter.com/PlayFromHeart/status/1501870568408240137?ref_src=twsrc%5Etfw">March 10, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}

                    </div>
                </ParallaxLayer>
            </Parallax>
        </div>
    );
}

export default App;
